<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import Card from '@/components/card/Card.vue'
import { datetimeMixins, toastMixins } from '@/mixins'
import userService from '@/services/user/user'

import MenuBar from './components/MenuBar.vue'

export default {
  mixins: [datetimeMixins, toastMixins],
  components: { AppCard: Card, MenuBar },
  data() {
    return {
      errMsg: null,
      isLoading: false,
      fields: [
        {
          label: 'รหัสผ่านปัจจุบัน',
          key: 'currentPassword',
          type: 'string'
        },
        {
          label: 'รหัสผ่านใหม่',
          key: 'newPassword',
          type: 'string'
        },
        {
          label: 'ยืนยันรหัสผ่านใหม่',
          key: 'newPasswordConfirm',
          type: 'string'
        }
      ],
      updatedData: {
        currentPassword: null,
        newPassword: null,
        newPasswordConfirm: null
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      defaultDelayMs: (state) => state.constants.defaultDelayMs
    }),
    saveButtonEnable() {
      const values = Object.keys(this.updatedData).map((key) => {
        const value = this.updatedData[key]
        if (
          _.isNil(value) ||
          (_.isString(value) && value.trim().length === 0)
        ) {
          return null
        }
        return value.trim()
      })
      return _.compact(values).length > 0
    }
  },
  methods: {
    mapKeyLabel(key) {
      const foundField = this.fields.find((field) => field.key === key)
      if (foundField) return foundField.label
      return '-'
    },
    buttonClicked(type) {
      switch (type) {
        case 'SAVE':
          this.changeMyPassword()
          break
        case 'CANCEL':
          this.$router.back()
          break
      }
    },
    async changeMyPassword() {
      this.isLoading = true
      try {
        const args = {}
        Object.keys(this.updatedData).forEach((key) => {
          const value = this.updatedData[key]
          if (
            _.isNil(value) ||
            (_.isString(value) && value.trim().length === 0)
          ) {
            args[key] = null
          } else {
            args[key] = value.trim()
          }
        })
        await userService.changeMyPassword(args)
        this.mxDisplaySuccessMessage('เปลี่ยนรหัสผู้ใช้งานสำเร็จ')
        setTimeout(() => {
          this.$router.replace({ name: 'User.BasicInfo' })
          this.$store.dispatch('fetchOwnInformation')
        }, this.defaultDelayMs)
      } catch (err) {
        this.errMsg = err.response?.data?.thMessage || 'เกิดข้อผิดพลาดในระบบ'
      }
      this.isLoading = false
    }
  }
}
</script>

<template>
  <b-container fluid="lg">
    <!-- page title / menu bar -->
    <b-row class="d-flex justify-content-between">
      <b-col class="col-12 col-xl-auto py-3 text-center text-xl-left">
        <page-title title="เปลี่ยนรหัสผ่านผู้ใช้งาน"></page-title>
      </b-col>
      <b-col
        class="col-12 col-xl-auto py-3 d-flex justify-content-center justify-content-xl-end"
      >
        <menu-bar></menu-bar>
      </b-col>
    </b-row>

    <!-- content -->
    <div class="py-3">
      <app-card>
        <b-row>
          <!-- topic -->
          <b-col cols="12" class="mb-2">
            <span class="text-lg font-weight-bold">
              เปลี่ยนรหัสผ่านผู้ใช้งาน
            </span>
          </b-col>

          <!-- content -->
          <b-col
            v-for="(value, key) in updatedData"
            :key="key"
            cols="12"
            class="mt-3 d-flex align-items-center justify-content-between"
          >
            <span class="mr-2 font-weight-bold text-nowrap">
              {{ mapKeyLabel(key) }} :
            </span>
            <b-input
              class="input-field-style"
              v-model="updatedData[key]"
              :disabled="isLoading"
              type="password"
            ></b-input>
          </b-col>
        </b-row>

        <!-- hover line -->
        <hr />

        <!-- buttons -->
        <b-row>
          <b-col
            cols="12"
            class="d-flex justify-content-center justify-content-xl-end"
          >
            <b-button
              variant="primary"
              class="mr-2 px-5"
              @click="buttonClicked('SAVE')"
              :disabled="!saveButtonEnable || isLoading"
            >
              <b-spinner v-if="isLoading" variant="light" small></b-spinner>
              <span v-else>บันทึก</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="px-5"
              @click="buttonClicked('CANCEL')"
              :disabled="isLoading"
            >
              ยกเลิก
            </b-button>
          </b-col>
        </b-row>

        <!-- error modal -->
        <error-modal
          :displayProp="!!errMsg"
          :errorMessageProp="errMsg"
          @modalClosed="errMsg = null"
        ></error-modal>
      </app-card>
    </div>
  </b-container>
</template>

<style scoped>
.input-field-style {
  width: 80%;
}
</style>
